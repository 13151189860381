import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { LocaleConsumer } from "../layout"

import locales from "../../helpers/locales"
import { withPrefix } from "gatsby"

const LocalizedLink = ({ to, ...props }) => (
  <LocaleConsumer>
    {locale => {
      const path = locales[props.locale].default ? to : `/${props.locale}${to}`

      return <Link {...props} to={withPrefix(path)} />
    }}
  </LocaleConsumer>
)

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default LocalizedLink
