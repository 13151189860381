import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Banner2 from "../components/Banner/banner2"
//import obcrei from "../data/assets/obcrei.svg"
import "../helpers/styles/typography.scss"
import LocalizedLink from "../components/utils/LocalizedLink"

export default ({ pageContext: { locale, currentPath }, data }) => {
  const content = data.childSobreObcreiJson
  const obcrei = content.Banner.logotipo.localFile.url

  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner2
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={true}
        logo={obcrei}
        opacityValue={0.8}
      />
      <div className="container">
        <div className="row justify-content-md-center">
          <h2 className="col-sm-10 col-xs-12">{content.aboutObcreiTitle}</h2>
          <div
            className="col-sm-10 col-xs-12 typo-body-regular-sobre-obcrei"
            dangerouslySetInnerHTML={{
              __html: content.textContent.data.textContent,
            }}
          />
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-md-center">
          <h3 className="col-sm-10 col-xs-12">{content.tecnicaTitle}</h3>
          <div
            className="col-sm-10 col-xs-12 typo-body-regular-sobre-obcrei"
            dangerouslySetInnerHTML={{
              __html: content.tecnicaContent.data.tecnicaContent,
            }}
          />
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center">
          <div className=" col-sm-10 col-xs-12 text-default color-primary">
            <LocalizedLink
              to="/obcrei"
              locale={locale}
              className="btn btn-creialab text--legend"
              style={{
                color: "#ffffff",
                minWidth: 146,
                height: 38,
                lineHeight: "25px",
                marginTop: "60px",
                marginBottom: "120px",
              }}>
              {content.goToDashboardButton}
            </LocalizedLink>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query obcreiAbout($locale: String!) {
    childSobreObcreiJson: strapiObcrei(locale: { eq: $locale }) {
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        logotipo {
          localFile {
            url
          }
        }
      }
      goToDashboardButton
      tecnicaTitle
      aboutObcreiTitle
      textContent {
        data {
          textContent
        }
      }
      tecnicaContent {
        data {
          tecnicaContent
        }
      }
    }
  }
`

// export const obcreiContent = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/sobreObcrei" }
//     ) {
//       childSobreObcreiJson {
//         title
//         bannerTitle
//         textContent
//         tecnicaTitle
//         tecnicaContent
//         dashboardButton
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `
